import { required, email } from 'vuelidate/lib/validators'
import { ZbResetPasswordModel } from './ZbResetPasswordModel'

export default {
  data: () => ({
    resetPasswordData: new ZbResetPasswordModel({}),
    serverError: false
  }),
  validations: {
    resetPasswordData: {
      email: { required, email }
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.resetPasswordData.email.$dirty) return errors
      !this.$v.resetPasswordData.email.required && errors.push(this.$t('validator.required', { field: this.$t('login.email') }))
      !this.$v.resetPasswordData.email.email && errors.push(this.$t('validator.email'))
      if (this.serverError) {
        errors.push(this.$t('validator.emailServer'))
      }
      return errors
    }
  },
  methods: {
    initData (param) {
      this.resetPasswordData = new ZbResetPasswordModel(param)
    }
  }
}
