<template>
  <zb-reset-password-component/>
</template>
<script>
import ZbResetPasswordComponent from '../modules/zb-reset-password'
export default {
  components: {
    ZbResetPasswordComponent
  }
}
</script>
